@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

img {
  max-width: 100%;
}

h2 {
  text-transform: uppercase;
  position: relative;
}

h2::before,
#contact-us h4::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 1px;
  display: block;
  background: #da0303;
  bottom: -15px;
  left: calc(50% - 50px);
}

h2::after,
#contact-us h4::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 3px;
  display: block;
  background: #da0303;
  bottom: -16px;
  left: calc(50% - 25px);
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.main-banner {
  /* background-image: url("./images/banner.jpeg"); */
  height: 100vh;
  background-size: cover;
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 0px 2px #878787;
}

.navbar-nav {
  margin-left: auto;
  background-color: #fff;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.carousel-item,
.carousel-inner,
.carousel {
  height: 100%;
}

#home .carousel-caption {
  top: 162px;
  text-align: left;
  left: 0;
  width: 100%;
  height: calc(100% - 162px);
  align-content: center;
}

.navbar-nav li,
.main-menu.navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: 500;
  padding: 15px;
}

#basic-navbar-nav .main-menu.navbar-nav .nav-link {
  padding: 15px 25px;
}

.navbar-nav .nav-link {
  font-size: 15px;
}

.navbar-nav .nav-link.active-menu::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #da0303;
  display: block;
  margin: auto;
  margin-top: 4px;
}

.reduced-nav .navbar-brand img {
  width: auto;
  height: 90px;
  transition: 0.4s;
}

footer {
  background-color: #eaeaea;
  padding-top: 50px;
}

.foot-content {
  padding-bottom: 30px;
}

footer .col-md-4 {
  margin-bottom: 15px;
}

.insta-section .col-md-4 {
  padding-left: 8px;
  padding-right: 8px;
}

.insta-section img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100px;
}

.foot-content h4,
.contact-data h4 {
  margin-bottom: 25px;
  text-transform: uppercase;
}

.foot-content h4::before,
.contact-data h4::before {
  text-transform: uppercase;
  border-left: 3px solid #da0303;
  content: "";
  margin-right: 10px;
  width: 3px;
  height: 18px;
  display: inline-block;
}

.copyright_section {
  padding: 20px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
}

.subscribe-section input {
  background-color: transparent;
  border: 1px solid #a6a6a6;
  padding: 5px 20px;
  width: 100%;
  margin-bottom: 10px;
}

.subscribe-section button {
  padding: 5px 20px;
  border: 1px solid #a6a6a6;
  background-color: transparent;
  color: #a6a6a6;
  text-transform: uppercase;
  font-weight: 600;
}

.subscribe-section button:hover {
  border-color: #000;
  color: #000;
}

#contact-us {
  /* background: url("./images/map.png") no-repeat; */
  /* background-size: cover; */
}

.contact-data {
  padding: 100px 90px 50px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 400px;
  text-align: left;
  box-shadow: 0px 0px 6px #a5a5a5;
  font-size: 17px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.content-contact {
  margin: 0;
  padding: 0;
}

.content-contact>li {
  display: flex;
  padding: 5px 0;
}

.content-contact i {
  width: 30px;
  padding-top: 5px;
  flex-shrink: 0;
}

.content-contact a {
  color: #000;
  text-decoration: none;
}

#menu img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#menu .col-md-3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#menu .title {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
}

#menu .title.sub-title {
  font-size: 13px;
  color: #656565
  }

.price {
  font-weight: 600;
  font-size: 18px;
  color: #da0303;
}

#services {
  background-color: #eaeaea;
}

#services .card {
  padding: 40px 20px;
  height: 100%;
  border: none;
}

#services i {
  font-size: 26px;
  margin-bottom: 20px;
}

#services .card:hover i {
  color: #da0303;
}

#services .card:hover {
  box-shadow: 0px 1px 2px #d0d0d0;
}

.card p {
  font-size: 16px;
  padding-top: 5px;
  text-align: center;
}

.fontIcon {
  font-size: 26px;
  padding-bottom: 15px;
  color: #b80c0c;
}

#about img {
  padding-right: 30px;
}

.about-content {
  padding-top: 20px;
}

.about-content p {
  line-height: 28px;
  text-align: left;
}

#menu .nav-link {
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
}

#menu .nav-link.active {
  color: #da0303;
  background-color: transparent;
  border: none;
}

#menu .nav-link:hover,
#menu li.nav-item:hover,
#menu li.nav-item:focus {
  border-color: transparent;
}

.nav-category-menu li {
  width: 100%;
  margin: 0;
}

.nav-category-menu .nav-link {
  width: 100%;
  padding: 13px 20px;
  text-align: left;
  box-shadow: 0 1px 0px #dfdfdf;
}

#about {
  padding-bottom: 30px;
}

#menu .nav-category-menu .nav-link:hover {
  color: #da0303;
}

#menu .tab-content {
  width: 100%;
}

.navbar-nav-footer {
  margin-left: auto;
  padding-left: 0;
}

.navbar-nav-footer li {
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px;
  list-style: none;
  margin-bottom: 4px;
}

.navbar-nav-footer li a {
  list-style: none;
  color: #565555;
  font-size: 15px;
}

.serviceHead {
  color: #2e2d2d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}

.fontAddressIcon {
  padding-right: 15px;
  padding-top: 5px;
}

.phoneNumbers {
  margin-bottom: 4px;
}

.nav-category-menu {
  width: 18%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* Make list vertical */
  align-items: flex-start;
  background-color: #f1f1f1;
  margin-top: 15px;
  margin-right: 20px;
  /* padding-top: 10px; */
  box-shadow: 5px 5px 5px rgb(235, 234, 234);
}

.nav-item {
  margin-bottom: 10px;
  /* Add space between the items */
}

.tab-container {
  display: flex;
}

.nav-category {
  width: 200px;
  /* Adjust width as needed */
  border-right: 1px solid #ddd;
}

.tab-content-area {
  padding-left: 20px;
  flex: 1;
  /* Take up the remaining space on the right */
}

.navbar-brand img {
  width: 135px;
  height: 135px;
}

#services .card:hover {
  background-color: #252525;
  color: #fff;
  transition: 0.4s;
}

#services .card:hover h5,
#services .card:hover svg {
  color: #fff;
}

.social-nav-footer li {
  display: inline-block;
}

.social-nav-footer li svg {
  font-size: 24px;
}

.nav-link.phone-no-item {
  font-size: 18px;
  color: #da0303;
}

.counter-content {
  background-image: url(./images/counter-bg.png);
  background-size: cover;
  padding: 100px 0;
  position: relative;
}

.overlay {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}

#counter {
  position: relative;
}

.counter-data {
  font-size: 50px;
  font-weight: 700;
}

.counter-div {
  color: #fff;
  /* width: 200px; */
  text-align: center;
}

.counter-div h6 {
  font-size: 20px;
  font-weight: 500;
}

/* menu */
#menu-page .menu-main-card img {
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
}

#menu-page .menu-main-card {
  display: block;
  position: relative;
}

.cat-item-title span {
  text-align: right;
}

.menu-card-title {
  position: absolute;
  top: 25%;
  color: #000;
  padding: 14px 25px 14px 20px;
  background: rgba(255, 255, 255, 1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menu-main-card:hover .menu-card-title {
  background-color: #fff;
}

.dotted-div {
  border-bottom: 2px dotted #000;
  width: 95px;
  margin-bottom: 6px;
}

#menu .item-card img {
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.social-nav-header li svg {
  color: #000;
}

.menu-header-phone {
  text-decoration: none;
  margin-right: 20px;
}

.menu-header-container {
  border-bottom: 1px solid #ebe7e7;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.menu-header-whatsapp svg {
  font-size: 23px;
}

.menu-header-whatsapp:hover {
  color: #24c83f;
}

.menu-page-content {
  min-height: calc(100vh - 308px);
}

#menu-page-catogories {
  width: 270px;
  }

#menu-page-catogories-tab-head {
  border-radius: 0;
  font-size: 17px;
  background: #3e3e3e;
  color: #fff !important;
  padding: 8px 20px;
}

.cat-item-title {
font-size: 15px;
}

/* #menu-page .menu-main-card img:hover {
  zoom: 1.2;
} */

.contact-data-container {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}

.contact-data-container .container {
  height: 100%;
}

#contact-us {
  position: relative;
}

#contact-us h4 {
  text-align: left;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 40px;
}

#contact-us h4::after {
  left: 49px;
}

#contact-us h4::before {
  left: 23px;
}

.footer-whatsapp {
  font-size: 20px;
}

.bannerHead {
  font-size: 60px;
  font-weight: 700;
}

.bannerSubHead {
  font-size: 24px;
  font-weight: 400;
  color: #c6c6c6;
}

.banner-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px;
  width: fit-content;
}

.phone-no-item svg {
  margin-right: 8px;
}

.footer-about-sec {
  text-align: left;
}

.nav-link.phone-no-item,
.menu-header-phone {
  font-size: 18px;
  color: #000;
  /* text-shadow: 0 0 1px #fff; */
}

.reduced-nav #basic-navbar-nav .main-menu.navbar-nav .nav-link.phone-no-item,
.menu-header-phone:hover {
  font-size: 18px;
  color: #da0303;
}

.drop-cat-title {
  width: calc(100% - 30px)
}

#menu-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-map {
  position: relative;
}

@media only screen and (max-width: 991px) {
  #menu .nav-link {
    font-size: 14px;
  }

  #about img {
    padding-right: 0;
    margin-bottom: 25px;
  }

  .nav-category-menu {
    width: 145px;
  }

  #basic-navbar-nav .main-menu.navbar-nav .nav-link.active-menu.nav-link {
    display: inline-block;
    width: max-content;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .bannerHead {
    font-size: 50px;
  }

  .contact-data-container {
    position: relative;
  }

  .contact-data {
    width: 100%;
    padding: 50px 0;
    box-shadow: unset;
  }

  .footer-about-sec {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .contact-data {
    padding: 60px 30px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 300px;
    max-width: 100%;
  }

  #menu .tab-container.mobile-display {
    display: block;
  }

  #menu-dropdown {
    width: 100%;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .counter-data {
    font-size: 40px;
  }

  .counter-div {
    margin-bottom: 15px;
  }

  .bannerHead {
    font-size: 28px;
    font-weight: 600;
  }

  .bannerSubHead {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .menu-header-container.container {
    flex-direction: column;
  }
}

.mob-category-list.navbar {
  padding: 0;
  box-shadow: none;
  margin-bottom: 20px;
  }
  
  .mob-category-list ul{
     display: flex;
     list-style: none;
      padding: 0;
      overflow-x: auto;
      margin: 0;
  }
  .mob-category-list ul li{
  flex-shrink: 0;
  padding: 10px;
  font-size: 14px;
  } 
  .mob-category-list ul li a {
    text-decoration: none;
    color: inherit
  }
  
  #menu .item-card-mob{
    margin-bottom: 20px;
  }
  
  #menu .item-card-mob img {
    width: 60px;
      height: 60px;
      box-shadow: 2px 2px 1px 0px #73737359;
      border-radius: 10%;
      margin-top: -15px;
      flex-shrink: 0;
  }
  
  #menu .item-card-mob h6 {
    background: rgb(64 64 64 / 11%);
    padding: 5px;
    padding-left: 84px;
    font-size: 15px;
    text-align: left;
    margin-bottom: 0;
  }

  #menu .mobile-display h6{
    text-align: left ;
  }
  
    .mob-item-details {
      padding: 5px
    }
  
    .description-det {
      padding-left: 20px;
      width: 100%;
      font-size: 13px;
    }
  
    .ar-name {
      color: rgb(105 103 103);
      
    }
   
    .accordion-item h2::before, .accordion-item h2::after {
      background: none;
    }
  
    #menu .accordion-button {
  
    background-color: transparent;
      border-left: 5px solid #ccc;
  }

  .it-price {
    font-size: 15px;
  }

  .mob-category-list ul li a.active::after {
    content: '';
    display: block;
    background: rgba(255, 93, 46, 0.9);
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .itemModal img {
    width: 100%;
    object-fit: cover;
  }
  
  .itm-modal-header.modal-header {
    padding: 0;
    border: none;
    position: absolute;
    right: -8px;
    background: #414141;
    border-radius: 50px;
    width: 26px;
    height: 26px;
    top: -8px;
    z-index: 10;
  }
  
    .itm-modal-header.modal-header .btn-close {
      padding: 0;
      position: absolute;
      right: 13px;
      filter: invert(1);}